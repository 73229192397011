import * as React from 'react';

import CLayout from '../components/contact-layout';

import Seo from '../components/seo';

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/styles.scss';
import '../styles/contact.scss';

const utmParameters = `?utm_source=starter&utm_medium=start-page&utm_campaign=default-starter`;

const ContactPage = () => <CLayout page='contact' />;

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
	<Seo
		title="There's More to Know | Share"
		description="There's more to know about sleep, wakefulness, and sleep disorders such as narcolepsy. Share this site with a friend or colleague."
	/>
);

export default ContactPage;
